import { GameConfig, defaultConfig, printConfig } from "./config";
import { GameStarter } from "./game";

const customConfig: any = getSettingsFromURI(location.search);

let config: any = defaultConfig;

if (customConfig) {
  if (customConfig.bonusFontSize) {
    customConfig.bonusSize = customConfig.bonusFontSize;
    customConfig.bonusFontSize = undefined;
  }
  if (customConfig.timeBonusFontSize) {
    customConfig.timeBonusSize = customConfig.timeBonusFontSize;
    customConfig.timeBonusFontSize = undefined;
  }
}

if (customConfig)
  for (const [key, value] of Object.entries(customConfig)) {
    if (value === "true" || value === "false") {
      config[key] = value === "true" ? true : false;
    } else {
      config[key] = value;
    }
  }

printConfig(defaultConfig);
const game = new GameStarter(<GameConfig>config);

function getSettingsFromURI(uri: string) {
  let settingString;
  const split = uri.split("&");
  for (let s of split) {
    if (s[0] === "s") {
      settingString = atob(s.split("=")[1]);
    }
  }

  if (settingString === undefined) {
    return null;
  }

  let jsonString = "{";
  let settings: string[] = settingString.split("\n");

  settings.forEach((s) => {
    const keyAndValue = s.split("=");
    if (keyAndValue.length === 1) {
      return;
    }

    const key = keyAndValue[0].trim();
    const value = keyAndValue[1].trim();

    if (value === "default" || key === "cache") {
      return;
    }

    let line = '"' + key + '": ';
    if (isString(value)) {
      line += '"' + value + '"';
    } else {
      line += value;
    }
    if (settings.indexOf(s) < settings.length - 2) {
      line += ",\n";
    }

    jsonString += line;
  });

  jsonString += "}";
  const config = JSON.parse(jsonString);

  if (jsonString.includes("bonus_")) {
    config["matchBonus"] = [];
  }

  if (jsonString.includes("matchSound_")) {
    config["matchSounds"] = [];
  }

  for (let key in config) {
    if (key.includes("bonus_")) {
      let number = key.split("bonus_")[1];
      config.matchBonus.push({ match: +number, bonus: config[key] });
    }
    if (key.includes("matchSound_")) {
      let number = key.split("matchSound_")[1];
      config.matchSounds.push({ match: +number, soundUrl: config[key] });
    }
  }

  return config;
}

function isString(value: string) {
  if (!isNaN(Number(value))) {
    return false;
  }
  return true;
}
