import { SnoepSoep } from "./game";

export class Board {
    rows: number;
    cols: number;
    blockVariations: number;

    grid: any[] = [];
    reserveGrid: any[];
    RESERVE_ROW: number;

    game: SnoepSoep;

    constructor(rows: number, cols: number, blockVariations: number, game: SnoepSoep){
        this.rows = rows;
        this.cols = cols;
        this.blockVariations = blockVariations;

        this.game = game;

        var i,j;
        for(i = 0; i < rows; i++) {
            this.grid.push([]);

            for(j = 0; j < cols; j++) {
            this.grid[i].push(0);
            }
        }

        //reserve grid on the top, for when new blocks are needed
        this.reserveGrid = [];

        this.RESERVE_ROW = rows;

        for(i = 0; i < this.RESERVE_ROW; i++) {
            this.reserveGrid.push([]);

            for(j = 0; j < cols; j++) {
            this.reserveGrid[i].push(0);
            }
        }

        this.populateGrid();
        this.populateReserveGrid();
    }

    populateGrid(){
        var i,j,variation;
        for(i = 0; i < this.rows; i++) {
            for(j = 0; j < this.cols; j++) {
            variation = this.findNewVariation(i, j, 0);
            // variation = Math.floor(Math.random() * this.blockVariations) + 1;
            this.grid[i][j] = variation;
            }
        }

        //if there are any chains, re-populate
        var chains = this.findAllChains();
        if(chains.length > 0) {
            this.populateGrid();
        }
    }

    populateReserveGrid(){
        var i,j,variation;
        for(i = 0; i < this.RESERVE_ROW; i++) {
          for(j = 0; j < this.cols; j++) {
            let loop = 0;
            this.reserveGrid[i][j] = this.findNewVariation(i, j, loop);
          }
        }

      };

      findNewVariation(i: number, j: number, loop: number): number{
        let variation = Math.floor(Math.random() * this.blockVariations) + 1;
        if(variation)
        this.reserveGrid[i][j] = variation;
        if(this.isChainedInReserve({row: i, col: j}) && loop < 5){
          loop += 1;
          return this.findNewVariation(i, j, loop);
        }
        return variation;
      }

      consoleLog() {
        var i,j;
        var prettyString = '';

        for(i = 0; i < this.RESERVE_ROW; i++) {
          prettyString += '\n';
          for(j = 0; j < this.cols; j++) {
            prettyString += ' ' + this.reserveGrid[i][j];
          }
        }

        prettyString += '\n';

        for(j = 0; j < this.cols; j++) {
          prettyString += ' -';
        }

        for(i = 0; i < this.rows; i++) {
          prettyString += '\n';
          for(j = 0; j < this.cols; j++) {
            prettyString += ' ' + this.grid[i][j];
          }
        }
      };


      swap(source: any, target: any) {
        var temp = this.grid[target.row][target.col];
        this.grid[target.row][target.col] = this.grid[source.row][source.col];
        this.grid[source.row][source.col] = temp;

        var tempPos = {row: source.row, col: source.col};
        source.row = target.row;
        source.col = target.col;

        target.row = tempPos.row;
        target.col = tempPos.col;
      };

      /*
      check if two blocks are adjacent
      return adjacent block
      */
      checkAdjacent(source: any, target: any) {
        var diffRow = source.row - target.row;
        var diffCol = source.col - target.col;

        if(diffCol > 0 && diffRow === 0){
          return this.game.getBlockFromColRow({col: source.col - 1, row: source.row })
        }
        else if(diffCol < 0 && diffRow === 0){
          return this.game.getBlockFromColRow({col: source.col + 1, row: source.row })
        }
        else if(diffRow > 0 && diffCol === 0){
          return this.game.getBlockFromColRow({col: source.col, row: source.row  - 1 })
        }
        else if(diffRow < 0 && diffCol === 0){
          return this.game.getBlockFromColRow({col: source.col, row: source.row + 1})
        } else {
          return undefined;
        }
      };

      /*
      check whether a single block is chained or not
      */
      isChained(block: any) {
        var isChained = false;
        var variation = this.grid[block.row][block.col];
        var row = block.row;
        var col = block.col;

        //left
        if(variation == this.grid[row][col - 1] && variation == this.grid[row][col - 2]) {
          isChained = true;
        }

        //right
        if(variation == this.grid[row][col + 1] && variation == this.grid[row][col + 2]) {
          isChained = true;
        }

        //up
        if(this.grid[row-2]) {
          if(variation == this.grid[row-1][col] && variation == this.grid[row-2][col]) {
            isChained = true;
          }
        }

        //down
        if(this.grid[row+2]) {
          if(variation == this.grid[row+1][col] && variation == this.grid[row+2][col]) {
            isChained = true;
          }
        }

        //center - horizontal
        if(variation == this.grid[row][col - 1] && variation == this.grid[row][col + 1]) {
          isChained = true;
        }

        //center - vertical
        if(this.grid[row+1] && this.grid[row-1]) {
          if(variation == this.grid[row+1][col] && variation == this.grid[row-1][col]) {
            isChained = true;
          }
        }

        return isChained;
      };

      isChainedInReserve(block: any){
        var isChained = false;
        var variation = this.reserveGrid[block.row][block.col];
        var row = block.row;
        var col = block.col;

        //left
        if(variation == this.reserveGrid[row][col - 1] && variation == this.reserveGrid[row][col - 2]) {
          isChained = true;
        }

        //right
        if(variation == this.reserveGrid[row][col + 1] && variation == this.reserveGrid[row][col + 2]) {
          isChained = true;
        }

        //up
        if(this.grid[row-2]) {
          if(variation == this.reserveGrid[row-1][col] && variation == this.reserveGrid[row-2][col]) {
            isChained = true;
          }
        }

        //down
        if(this.grid[row+2]) {
          if(variation == this.reserveGrid[row+1][col] && variation == this.reserveGrid[row+2][col]) {
            isChained = true;
          }
        }

        //center - horizontal
        if(variation == this.reserveGrid[row][col - 1] && variation == this.reserveGrid[row][col + 1]) {
          isChained = true;
        }

        //center - vertical
        if(this.reserveGrid[row+1] && this.reserveGrid[row-1]) {
          if(variation == this.reserveGrid[row+1][col] && variation == this.reserveGrid[row-1][col]) {
            isChained = true;
          }
        }

        return isChained;
      }
      /*
      find all the chains
      */
      findAllChains() {
        var chained = [];
        var i, j;

        for(i = 0; i < this.rows; i++) {
          for(j = 0; j < this.cols; j++) {
            if(this.isChained({row: i, col: j})) {
              var variation = this.grid[i][j];
              chained.push({row: i, col: j, variation: variation});
            }
          }
        }

        return chained;
      };

      findChainsInReserve(){
        var i, j;

        for(i = 0; i < this.rows; i++) {
          for(j = 0; j < this.cols; j++) {
            if(this.isChained({row: i, col: j})) {
              return true;
            }
          }
        }

        return false;
      }

      /*
      clear all the chains*/
      clearChains (){
        //gets all blocks that need to be cleared
        var chainedBlocks = this.findAllChains();

        //set them to zero
        chainedBlocks.forEach((block) => {
          this.grid[block.row][block.col] = 0;
          //kill the block object
          this.game.getBlockFromColRow(block)?.kill();
        });

        return chainedBlocks;
      };

      /*
      drop a block in the main grid from a position to another. the source is set to zero
      */
      dropBlock(sourceRow: any, targetRow: any, col: any){
        this.grid[targetRow][col] = this.grid[sourceRow][col];
        this.grid[sourceRow][col] = 0;

        this.game.dropBlock(sourceRow, targetRow, col);
      };

      /*
      drop a block in the reserve grid from a position to another. the source is set to zero
      */
      dropReserveBlock(sourceRow: any, targetRow: any, col: any){
        this.grid[targetRow][col] = this.reserveGrid[sourceRow][col];
        this.reserveGrid[sourceRow][col] = 0;

        this.game.dropReserveBlock(sourceRow, targetRow, col);
      };

      /*
      move down blocks to fill in empty slots
      */
      updateGrid(){



        var i, j, k, foundBlock;

        //go through all the rows, from the bottom up
        for(i = this.rows - 1; i >= 0; i--){
          for(j = 0; j < this.cols; j++) {
            //if the block if zero, then get climb up to get a non-zero one
            if(this.grid[i][j] === 0) {
              foundBlock = false;

              //climb up in the main grid
              for(k = i - 1; k >= 0; k--) {
                if(this.grid[k][j] > 0) {
                  this.dropBlock(k, i, j);
                  foundBlock = true;
                  break;
                }
              }

              if(!foundBlock) {
                //climb up in the reserve grid
                for(k = this.RESERVE_ROW - 1; k >= 0; k--) {
                  if(this.reserveGrid[k][j] > 0) {
                    this.dropReserveBlock(k, i, j);
                    break;
                  }
                }
              }
            }
          }
        }

        //repopulate the reserve
        this.populateReserveGrid();

        // this.consoleLog();
      }


}