export interface GameConfig {
  path: string;
  imageBaseName: string;

  corsFixed: boolean;
  useSVG: boolean;

  size: number;
  numberOfVariations: number;
  blockSize: number;
  swapAnimationTime: number;
  physicsAnimationTime: number;

  bonusPerMs: number;
  startTimeLimit: number;
  endTimeLimit: number;
  levelTimeUp: number;
  matchBonus: { match: number; bonus: number }[];

  timeBarColor: string;
  timeBarBackgroundColor: string;
  timeBarBorderRadius: number;
  timeBarBorderColor: string;
  timeBarBorderWidth: number;
  timeBarOffsetY: number;

  gridOffsetY: number;

  bonusFont: string;
  bonusColor: string;
  bonusSize: number;
  bonusFontWeight?: string;
  bonusFontStroke?: string;

  timeBonusFont: string;
  timeBonusColor: string;
  timeBonusSize: number;
  timeBonusOffsetY: number;
  timeBonusFontWeight?: string;
  timeBonusFontStroke?: string;
  timeBonusAnimationTime: number;

  scoreFont: string;
  scoreSize: number;
  scoreColor: string;
  scoreOffsetY: number;

  swipeSound?: string;
  matchSounds?: [{ match: number; soundUrl: string }];
  timeBonusSound?: string;
  timeUpSound?: string;
  dropBlockSound?: string;
  selectSound?: string;
}

export function printConfig(config: any) {
  let string = "";
  for (const [key, value] of Object.entries(config)) {
    string += key + "=" + value + "\n";
  }
}

export const defaultConfig: GameConfig = {
  imageBaseName: "candy",
  path: "https://gamecdn.playenwin.com/wietse/candycrush/default/",
  corsFixed: true,
  useSVG: false,
  size: 5,
  numberOfVariations: 3,
  blockSize: 30,
  swapAnimationTime: 180,
  physicsAnimationTime: 500,

  bonusPerMs: 10,
  startTimeLimit: 5000,
  endTimeLimit: 1000,
  levelTimeUp: 500,

  timeBarColor: "#06BB00",
  timeBarBackgroundColor: "grey",
  timeBarBorderColor: "black",
  timeBarBorderRadius: 0,
  timeBarBorderWidth: 3,
  timeBarOffsetY: 0,

  gridOffsetY: 0,

  matchBonus: [{ match: 3, bonus: 100 }],

  scoreFont: "Arial",
  scoreColor: "black",
  scoreSize: 30,
  scoreOffsetY: 0,
  bonusFont: "Arial",
  bonusColor: "yellow",
  bonusSize: 1,
  timeBonusFont: "Arial",
  timeBonusColor: "pink",
  timeBonusFontWeight: "400",
  timeBonusOffsetY: 0,
  timeBonusSize: 20,

  timeBonusAnimationTime: 500,

  // swipeSound: "swipe.wav",
  // matchSound: "match.wav",
  // timeBonusSound: "sound.mp3",
  // dropBlockSound: "noise.mp3",
  // selectSound: "sound.mp3",
};
