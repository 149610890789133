import { SnoepSoep } from "./game";

export class Block extends Phaser.GameObjects.DOMElement {
  row: number = 0;
  col: number = 0;

  // keepScale: number;

  game: SnoepSoep;
  constructor(
    x: number,
    y: number,
    data: any,
    scene: SnoepSoep,
    spaceBetween: number
  ) {
    super(
      scene,
      x,
      y,
      "div",
      `width: ${SnoepSoep.config.blockSize}px; height: ${SnoepSoep.config.blockSize}px;`
    );

    this.pointerEvents = "none";

    // this.keepScale = SnoepSoep.config.blockSize / 35;

    this.game = scene;

    this.row = data.row;
    this.col = data.col;

    // this.setScale(this.keepScale);

    this.setOrigin(0.5);

    this.setDepth(5);

    // document.body.appendChild(this.node);

    this.setHTML(data.asset.outerHTML);

    this.removeInteractive();

    // this.setSize((SnoepSoep.config.blockSize + spaceBetween) * 1, (SnoepSoep.config.blockSize + spaceBetween) * 1);

    // this.setDisplaySize(SnoepSoep.config.blockSize * 1, SnoepSoep.config.blockSize * 1);

    // this.on('mousedown', () => this.game.pickBlock(this));

    // this.on('pointerup', () => {
    //     this.game.pickBlock(this);
    // });
  }

  pointerOver(x: number, y: number, first: boolean, spaceBetween: number) {
    let xmin = this.x - SnoepSoep.config.blockSize / 2 - spaceBetween;
    let xmax = this.x + SnoepSoep.config.blockSize / 2 + spaceBetween;
    let ymin = this.y - SnoepSoep.config.blockSize / 2 - spaceBetween;
    let ymax = this.y + SnoepSoep.config.blockSize / 2 + spaceBetween;
    if (first) {
      return x > xmin && x < xmax && y > ymin && y < ymax;
    } else {
      if (this.col === 0) {
        xmin -= 10;
      }
      if (this.col === SnoepSoep.config.size - 1) {
        xmax += 10;
      }
      if (this.row === 0) {
        ymin -= 200;
      }
      if (this.row === SnoepSoep.config.size - 1) {
        ymax += 200;
      }
      return x > xmin && x < xmax && y > ymin && y < ymax;
    }
  }

  reset(x: number, y: number, data: any) {
    // Phaser.Sprite.prototype.reset.call(this, x, y);
    this.setDepth(5);
    this.setAlpha(1);
    this.setPosition(x, y);
    this.setHTML(data.asset.outerHTML);
    this.row = data.row;
    this.col = data.col;
  }

  kill() {
    const length = SnoepSoep.config.timeBonusAnimationTime
      ? +SnoepSoep.config.timeBonusAnimationTime
      : 500;
    this.scene.add.tween({
      targets: this,
      duration: length / 4,
      scaleX: "/=2",
      scaleY: "/=2",
      repeat: 2,
      yoyo: true,
    });
    this.scene.add.tween({
      targets: this,
      duration: length / 2,
      alpha: 0,
      delay: length / 2,
    });
    setTimeout(() => {
      this.destroy(true);
    }, length);
  }
}
