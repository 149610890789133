import Phaser from "phaser";
import { Block } from "./block";
import { Board } from "./board";
import { GameConfig } from "./config";
import { Platform } from "./Platform";
import WebFontFile from "./webfontload";

export class GameStarter {
  path: string = "";
  imageLoadCounter = 0;
  config: GameConfig;

  constructor(config: GameConfig) {
    this.path = config.path;
    this.imageLoadCounter = config.numberOfVariations;
    this.config = config;
    this.config.blockSize = (300 / this.config.size) * 0.9;
    document.addEventListener("DOMContentLoaded", () => {
      this.loadImages();
    });
  }

  loadImages() {
    for (let i = 0; i < this.imageLoadCounter; i++) {
      this.load(
        this.config.imageBaseName + (i + 1),
        this.config.imageBaseName +
          (i + 1) +
          (this.config.useSVG ? ".svg" : ".png")
      );
    }
  }

  load(key: string, src: string) {
    let img = new Image();
    img.id = key;
    // img.hidden = true;
    img.onload = () => {
      document.body.append(img);
      this.imageReady();
    };
    img.onerror = () => {
      console.error("Img " + key + " with source " + src + " not found");
      document.body.append(img);
      this.imageReady();
    };
    // if (src.includes(".png")) {
    img.width = this.config.blockSize;
    img.height = this.config.blockSize;

    img.style.userSelect = "none";
    img.style.pointerEvents = "none";
    img.src = this.path + src;
  }

  init() {
    const container = document.getElementById("container");
    const phaserConfig: Phaser.Types.Core.GameConfig = {
      type: Phaser.CANVAS,
      parent: container ? container : undefined,
      width: 320 * 1,
      height: 570 * 1,
      scene: [new SnoepSoep(this.config)],
      render: {
        transparent: true,
        antialias: true,
      },

      scale: {
        mode: Phaser.Scale.WIDTH_CONTROLS_HEIGHT,
        autoCenter: Phaser.Scale.CENTER_VERTICALLY,
      },
      //   roundPixels: true,

      dom: {
        createContainer: true,
        pointerEvents: "none",
      },
      audio: {
        disableWebAudio: true,
      },
      //   pixelArt: true,
    };

    const game = new Phaser.Game(phaserConfig);
  }

  imageReady() {
    this.imageLoadCounter--;
    if (this.imageLoadCounter === 0) {
      this.init();
    }
  }
}

export let SPEED: number = 1;

export class SnoepSoep extends Phaser.Scene {
  static config: GameConfig;
  static width: number;
  static height: number;

  playing: boolean = false;

  platform: Platform;

  score: number = 0;

  blocks!: Phaser.GameObjects.Group;
  currentTimerBarWidth: number = 100;

  timeBonusElement: HTMLDivElement;

  board!: Board;

  _time: number = 0;

  isBoardBlocked: boolean = false;
  selectedBlock: any;
  targetBlock?: Block;
  isReversingSwap: any;

  spaceBetween: number = 0;

  timeLimit: number;

  timeBonus: boolean = false;

  imgs: any = {};

  constructor(config: GameConfig) {
    super({});
    SnoepSoep.config = config;
    this.platform = new Platform();
    this.timeLimit = config.startTimeLimit;

    this.spaceBetween = (config.blockSize / 0.9) * 0.1;
  }

  preload() {
    this.load.addFile(
      new WebFontFile(this.load, [
        SnoepSoep.config.bonusFont +
          (SnoepSoep.config.bonusFontWeight
            ? `:${SnoepSoep.config.bonusFontWeight}`
            : ""),
        SnoepSoep.config.timeBonusFont +
          (SnoepSoep.config.timeBonusFontWeight
            ? `:${SnoepSoep.config.timeBonusFontWeight}`
            : ""),
      ])
    );

    this.load.path = SnoepSoep.config.path;

    for (let i = 0; i < SnoepSoep.config.numberOfVariations; i++) {
      const key = SnoepSoep.config.imageBaseName + (i + 1);
      const candy = <HTMLImageElement>document.getElementById(key);

      //   if (candy.src.includes(".png"))
      //   this.textures.addImage(key, candy);
      this.imgs[key] = candy;
      //   else this.load.html(key, candy.src);

      //   document.body.removeChild(candy);
    }
  }

  create() {
    this.timeBonusElement = document.getElementById(
      "timebonus"
    ) as HTMLDivElement;

    (this.timeBonusElement.style.font = `${
      SnoepSoep.config.timeBonusSize * 1
    }px ${SnoepSoep.config.timeBonusFont}`),
      (this.timeBonusElement.style.fontWeight =
        SnoepSoep.config.timeBonusFontWeight);
    this.timeBonusElement.style.webkitTextStroke =
      SnoepSoep.config.timeBonusFontStroke;
    (this.timeBonusElement.style.color = SnoepSoep.config.timeBonusColor),
      (this.timeBonusElement.style.top =
        SnoepSoep.config.timeBonusOffsetY + "px");

    this.timeBonusElement.style.pointerEvents = "none";

    this.platform.preloadSounds(SnoepSoep.config);
    this.blocks = this.add.group();

    const config = SnoepSoep.config;
    this.board = new Board(
      config.size,
      config.size,
      config.numberOfVariations,
      this
    );

    this.drawBoard();

    this.score = 0;

    this.input.on("gameout", () => {
      this.clearSelection();
    }),
      this.input.on("pointerdown", (pointer: any) => {
        if (this.playing === false) {
          return;
        }
        for (let i = 0; i < this.blocks.getChildren().length; i++) {
          const block = <Block>this.blocks.getChildren()[i];
          if (
            block.pointerOver(pointer.x, pointer.y, true, this.spaceBetween)
          ) {
            this.pickBlock(block);
            return;
          }
        }
      });

    this.input.on("pointermove", (pointer: any) => {
      if (this.playing === false) {
        return;
      }
      if (this.selectedBlock === null) {
        return;
      }
      for (let i = 0; i < this.blocks.getChildren().length; i++) {
        const block = <Block>this.blocks.getChildren()[i];
        if (
          block.pointerOver(pointer.x, pointer.y, false, this.spaceBetween) &&
          block !== this.selectedBlock
        ) {
          this.pickBlock(block);
          return;
        }
      }
    });

    this.input.on("pointerup", () => {
      if (this.playing === false) {
        return;
      }
      this.pickBlock(this.selectedBlock);
    });

    this.platform.init(this, SnoepSoep.config);
    this._time = SnoepSoep.config.startTimeLimit;
  }

  update(time: number, delta: number): void {
    if (this.playing === false || this.timeBonus === true) {
      return;
    }
    this._time -= delta;
    if (this._time < -50) {
      this.gameOver();
      return;
    }

    let size = (this._time / this.timeLimit) * 100;
    size = size < 0 ? 0 : size;

    this.platform.sendTimeleft(size);
    this.currentTimerBarWidth = size;

    // this.hourGlass.setSize(size, 10 * 1);
  }

  play() {
    this.playing = true;
    this.selectedBlock = null;
    this.isBoardBlocked = false;
    this.score = 0;
    this.platform.gamestarted();
  }

  gameOver() {
    // this.isBoardBlocked = true;
    this.playing = false;

    this.playSound("timeUp");

    this._time = SnoepSoep.config.startTimeLimit;
    this.timeLimit = SnoepSoep.config.startTimeLimit;

    this.platform.gameover(this.score);
    // setTimeout(() => {

    // }, 1000)
  }

  createBlock(x: number, y: number, data: any) {
    var block = this.blocks.getFirstDead();

    if (!block) {
      block = new Block(x, y, data, this, this.spaceBetween);
      this.blocks.add(block);
      this.add.existing(block);
    } else {
      block.reset(x, y, data);
    }

    return block;
  }

  reuseBlock(x: number, y: number, data: any) {
    var block = this.blocks.getFirstDead();
    if (!block) {
      setTimeout(() => {
        return this.reuseBlock(x, y, data), 10;
      });
    }
    block.reset(x, y, data);
  }

  restart() {
    this.blocks.clear(true, true);
    this.score = 0;
    this.board = new Board(
      SnoepSoep.config.size,
      SnoepSoep.config.size,
      SnoepSoep.config.numberOfVariations,
      this
    );
    this.drawBoard();
    // this.scoreText.setText(
    // 	this.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    // );

    // this.hourGlass.setSize(300 - this.spaceBetween, 10 * 1);
    this.platform.ready();
  }

  drawBoard() {
    var pos, x, y;
    for (let i = 0; i < SnoepSoep.config.size; i++) {
      for (let j = 0; j < SnoepSoep.config.size; j++) {
        pos = this.getPosition(j, i);

        this.createBlock(pos.x, pos.y, {
          asset:
            this.imgs[SnoepSoep.config.imageBaseName + this.board.grid[i][j]],
          row: i,
          col: j,
        });
      }
    }
  }

  getBlockFromColRow(position: any) {
    var foundBlock: Block;

    const blocks = this.blocks.getChildren();

    for (let i = 0; i < blocks.length; i++) {
      const block: Block = <Block>blocks[i];

      if (block.row === position.row && block.col === position.col) {
        return block;
      }
    }
    return undefined;
  }

  dropBlock(sourceRow: number, targetRow: number, col: number) {
    var block: Block | undefined = this.getBlockFromColRow({
      row: sourceRow,
      col: col,
    });

    if (block === undefined) {
      return;
    }
    var targetY = this.getPosition(0, targetRow).y;

    block.row = targetRow;

    this.tweens.add({
      targets: block,
      y: targetY,
      duration: SnoepSoep.config.physicsAnimationTime,
      ease: "Linear",

      repeat: 0,
    });
  }

  dropReserveBlock(sourceRow: number, targetRow: number, col: number) {
    var pos = this.getPosition(col, targetRow);

    var y =
      -SnoepSoep.config.blockSize * this.board.RESERVE_ROW +
      sourceRow * SnoepSoep.config.blockSize;

    var block = this.createBlock(pos.x, y, {
      asset:
        this.imgs[
          SnoepSoep.config.imageBaseName + this.board.grid[targetRow][col]
        ],
      row: targetRow,
      col: col,
    });
    var targetY = pos.y;

    this.tweens.add({
      targets: block,
      y: targetY,
      duration: SnoepSoep.config.physicsAnimationTime,
      ease: "Linear",
      repeat: 0,
    });
  }

  swapBlocks(block1: Block, block2: Block) {
    if (this.playing === false) {
      return;
    }

    this.playSound("swipe");

    //when swapping scale block1 back to 1

    block1.scale = 1;

    // block1.setDisplaySize(
    //   SnoepSoep.config.blockSize * 1,
    //   SnoepSoep.config.blockSize * 1
    // );

    let pos = this.getPosition(block2.col, block2.row);

    this.tweens.add({
      targets: block1,
      x: pos.x,
      y: pos.y,
      duration: SnoepSoep.config.swapAnimationTime,

      onComplete: () => {
        this.board.swap(block1, block2);

        if (!this.isReversingSwap) {
          var chains = this.board.findAllChains();

          if (chains.length > 0) {
            this.timeLimit -= SnoepSoep.config.levelTimeUp;
            if (this.timeLimit < SnoepSoep.config.endTimeLimit) {
              this.timeLimit = SnoepSoep.config.endTimeLimit;
            }
            this.addTimeBonus();
            this._time = this.timeLimit;
            this.playing = false;
            this.updateBoard(2);
          } else {
            this.isReversingSwap = true;
            this.swapBlocks(block1, block2);
          }
        } else {
          this.isReversingSwap = false;
          this.clearSelection();
        }
      },
    });

    pos = this.getPosition(block1.col, block1.row);

    this.tweens.add({
      targets: block2,
      x: pos.x,
      y: pos.y,
      duration: SnoepSoep.config.swapAnimationTime,
    });
  }

  pickBlock(block: Block) {
    //only swap if the UI is not blocked
    if (this.isBoardBlocked || this.playing === false || block === null) {
      return;
    }

    //if there is nothing selected
    if (!this.selectedBlock) {
      this.playSound("select");

      block.scale = 1.2;
      // highlight the first block
      //   block.setDisplaySize(
      //     1.2 * SnoepSoep.config.blockSize * 1,
      //     1.2 * SnoepSoep.config.blockSize * 1
      //   );
      block.setDepth(10);

      this.selectedBlock = block;
    } else {
      //second block you are selecting is target block
      this.targetBlock = this.board.checkAdjacent(this.selectedBlock, block);
      //only adjacent blocks can swap
      if (this.targetBlock) {
        //block the UI
        this.isBoardBlocked = true;

        //swap blocks
        this.swapBlocks(this.selectedBlock, this.targetBlock);
      } else {
        this.clearSelection();
      }
    }
  }

  clearSelection() {
    this.isBoardBlocked = false;
    this.selectedBlock = null;
    this.blocks.getChildren().forEach((block) => {
      if (block instanceof Block) {
        block.setDepth(0);

        block.scale = 1;
        // block.setDisplaySize(
        //   SnoepSoep.config.blockSize * 1,
        //   SnoepSoep.config.blockSize * 1
        // );
      }
    });
  }

  updateBoard(scoreMultiplier: number) {
    const chains = this.board.clearChains();

    let sortedChains = this.sortChains(chains);

    if (sortedChains) {
      for (let i = 0; i < sortedChains.length; i++) {
        this.updateScore(sortedChains[i], scoreMultiplier);
      }
    }

    setTimeout(
      () => {
        this.board.updateGrid();

        setTimeout(() => {
          var chains = this.board.findAllChains();
          this.playSound("dropBlock");
          if (chains.length > 0) {
            this.updateBoard(scoreMultiplier * 2);
          } else {
            this.timeBonus = false;
            this.isBoardBlocked = false;
            this.playing = true;
            this.clearSelection();
          }
        }, SnoepSoep.config.physicsAnimationTime);
      },
      SnoepSoep.config.timeBonusAnimationTime
        ? +SnoepSoep.config.timeBonusAnimationTime
        : 500
    );
  }

  getPosition(col: number, row: number) {
    const x =
      (10 / 0.9 +
        col * SnoepSoep.config.blockSize +
        SnoepSoep.config.blockSize / 2 +
        this.spaceBetween * col) *
      1;
    const y =
      (130 +
        SnoepSoep.config.gridOffsetY +
        row * SnoepSoep.config.blockSize +
        SnoepSoep.config.blockSize / 2 +
        this.spaceBetween * row) *
      1;
    return { x: x, y: y };
  }

  updateScore(chain: { col: number; row: number }[], scoreMultiplier: number) {
    const chainLength = chain.length;
    if (chainLength < 3) {
      return;
    }
    let points = this.getBonus(chainLength) * scoreMultiplier;
    let sound = this.getMatchSound(chainLength);

    this.score += points;

    this.platform.sendScore(this.score);

    // this.scoreText.setText(
    // 	this.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    // );

    this.drawPoints(points, chain);
  }

  getBonus(chainLength: number): number {
    if (chainLength < 3) {
      return 0;
    }
    const bonus = SnoepSoep.config.matchBonus.find(
      (bo) => bo.match === chainLength
    );

    if (bonus) {
      return bonus.bonus;
    } else {
      return this.getBonus(chainLength - 1);
    }
  }

  getMatchSound(chainLength: number) {
    if (chainLength < 3) {
      return;
    }
    const bonus = this.sounds["match_" + chainLength];

    if (bonus) {
      return bonus;
    } else {
      this.getMatchSound(chainLength - 1);
    }
  }

  sortChains(chains: { row: number; col: number; variation: number }[]) {
    chains = chains.sort((a, b) => (a.variation < b.variation ? -1 : 1));
    if (chains.length === 0) {
      return;
    }

    let sortedChains: { row: number; col: number; variation: number }[][] = [];

    chains.forEach((block) => {
      const index = chains.indexOf(block);
      if (index === chains.length - 1) {
      } else {
        const next = chains[index + 1];
        if (next.col !== block.col && next.row !== block.row) {
        } else {
          let chain: {
            col: number;
            row: number;
            variation: number;
          }[];
          if (next.col === block.col) {
            chain = chains.filter(
              (b) => b.col === block.col && b.variation === block.variation
            );
          } else {
            chain = chains.filter(
              (b) => b.row === block.row && b.variation === block.variation
            );
          }
          chain = chain
            .sort((a, b) => (a.col < b.col ? -1 : 1))
            .sort((a, b) => (a.row < b.row ? -1 : 1));

          if (
            !sortedChains.some(
              (c) => this.equalsIgnoreOrder(chain, c) && chain.length >= 3
            )
          )
            sortedChains.push(chain);
        }
      }
    });

    return sortedChains;
  }

  drawPoints(points: number, chain: { col: number; row: number }[]) {
    let pos = this.getPosition(chain[0].col, chain[0].row);

    const chainLength = chain.length;

    if (chain.length < 3) {
      return;
    }

    const directionX = chain[1].col - chain[0].col;
    const directionY = chain[1].row - chain[0].row;

    if (directionX < 0) {
      pos.x -=
        ((chainLength / 2) * SnoepSoep.config.blockSize -
          0.5 * SnoepSoep.config.blockSize +
          ((chainLength - 1) / 2) * this.spaceBetween) *
        1;
    }
    if (directionX > 0) {
      pos.x +=
        ((chainLength / 2) * SnoepSoep.config.blockSize -
          0.5 * SnoepSoep.config.blockSize +
          ((chainLength - 1) / 2) * this.spaceBetween) *
        1;
    }
    if (directionY > 0) {
      pos.y +=
        ((chainLength / 2) * SnoepSoep.config.blockSize -
          0.5 * SnoepSoep.config.blockSize +
          (chainLength / 2) * this.spaceBetween) *
        1;
    }
    if (directionY < 0) {
      pos.y -=
        ((chainLength / 2) * SnoepSoep.config.blockSize -
          0.5 *
            SnoepSoep.config.blockSize *
            (SnoepSoep.config.bonusSize ? +SnoepSoep.config.bonusSize : 1) +
          ((chainLength - 1) / 2) * this.spaceBetween) *
        1;
    }

    let pointsIndicator = this.add
      .dom(
        pos.x,
        pos.y,
        "div",
        {
          color: SnoepSoep.config.bonusColor,
          font: `${
            SnoepSoep.config.blockSize *
            0.5 *
            (SnoepSoep.config.bonusSize ? +SnoepSoep.config.bonusSize : 1)
          }px ${SnoepSoep.config.bonusFont}`,
          "text-align": "center",
          "-webkit-text-stroke": SnoepSoep.config.bonusFontStroke,
          "font-weight": SnoepSoep.config.bonusFontWeight,
        },
        "+" + points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      )
      .setOrigin(0.5);
    pointsIndicator.pointerEvents = "none";
    pointsIndicator.setDepth(40);

    setTimeout(
      () => {
        pointsIndicator.destroy();
      },
      SnoepSoep.config.timeBonusAnimationTime
        ? +SnoepSoep.config.timeBonusAnimationTime
        : 500
    );
  }

  equalsIgnoreOrder(a: any, b: any) {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter((e: any) => e === v).length;
      const bCount = b.filter((e: any) => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  }

  sounds = {} as any;

  playSound(soundKey: string, loop: boolean = false) {
    const config = SnoepSoep.config as any;
    if (config[soundKey + "Sound"])
      this.platform.playSound(config.path + config[soundKey + "Sound"]);
  }

  addTimeBonus() {
    if (this.timeBonus) return;
    if (this._time < 0) this.timeBonus = true;

    this.platform.startTimeBonus();
    const time = this._time > 0 ? this._time : 0;
    const bonus = Math.floor(
      time *
        (SnoepSoep.config.startTimeLimit / this.timeLimit) *
        SnoepSoep.config.bonusPerMs
    );

    this.timeBonusElement.style.transition = "";
    this.timeBonusElement.style.opacity = "1";

    const offsetTop = this.game.canvas.style.marginTop;

    const steps =
      (SnoepSoep.config.timeBonusAnimationTime
        ? +SnoepSoep.config.timeBonusAnimationTime
        : 500) / 10;

    const diff = this.currentTimerBarWidth / steps;

    for (let i = 0; i < steps + 1; i++) {
      setTimeout(() => {
        this.currentTimerBarWidth = Math.max(
          0,
          this.currentTimerBarWidth - diff
        );
        this.platform.sendTimeleft(this.currentTimerBarWidth);

        this.timeBonusElement.innerHTML = Math.round((bonus * i) / steps)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        this.playSound("timeBonus", true);
      }, 10 * i);
    }

    setTimeout(
      () => {
        this.timeBonusElement.style.transition = "opacity 1s linear";

        this.timeBonusElement.style.opacity = "0";
        this.score += bonus;
        this.platform.sendScore(this.score);
      },
      SnoepSoep.config.timeBonusAnimationTime
        ? +SnoepSoep.config.timeBonusAnimationTime
        : 500
    );
  }
}
